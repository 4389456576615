<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat>
        <v-toolbar-title>Bin Locations</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="accent" small to="/inventory/bin-locations/create">
          <v-icon left dark>mdi-plus</v-icon> New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field dense v-model="search" label="Search"></v-text-field>
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="BinLocations"
              :search="search"
            >
              <template v-slot:item.id="{ item, index }">
                <td>{{ index + 1 }}</td>
              </template>
              <template v-slot:item.created_at="{ item }">{{
                item.created_at | moment("LLL")
              }}</template>
              <template v-slot:item.action="props">
                <v-btn :to="`/ware-house/bin-locations/${props.item.id}`" icon>
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    search: "",
    loading: false,
    headers: [
      { text: "#", value: "id" },
      { text: "Name", value: "Descr" },
      { text: "Code", value: "BinCode" },
      { text: "Warehouse", value: "warehouse.WhsName" },
      { text: "Created On", value: "created_at" },
      { text: "Action", value: "action" },
    ],
    BinLocations: [],
  }),
  methods: {
    getBinLocations() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/warehouses/bin-location`)
        .then((res) => {
          if (res.ResultCode == 1200) {
            self.BinLocations = res.ResponseData;
            self.loading = false;
          }
          if (res.ResultCode == 1043) {
            this.$refs.snackbar.show(res.ResultDesc, "red");
            self.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.$refs.snackbar.show(err, "red");
          self.loading = false;
        });
    },
  },
  created() {
    this.getBinLocations();
  },
};
</script>